




















import Vue from 'vue'
import Logs from '../../../../store/models/Logs'

interface Log {
  id?: number
  dateTime: string
  information: string
  responsible: string
}

interface ComponentData {
  columns: Record<string, string>[]
  currentItemId: string | number | null
}

export default Vue.extend({
  data(): ComponentData {
    return {
      columns: [
        {
          label: 'Date et Heure',
          field: 'createdAt'
        },
        {
          label: 'Information',
          field: 'text'
        },
        {
          label: 'Responsable',
          field: 'causer.fullname'
        },
        {
          label: 'Action',
          field: '_action',
          align: 'right'
        }
      ],
      currentItemId: ''
    }
  },

  computed: {
    logs() {
      return Logs.query()
        .with('causer')
        .all()
    }
  },

  created() {
    Logs.api().fetch()
  },

  methods: {
    onSeeDetails() {
      // TODO: Write route to details page
      // this.$router.push({ name: 'logs.details', params: { logId: id } })
    }
  }
})
